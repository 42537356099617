import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Navbar from '../../navbar/navbar';
import { CallToActionButton } from '../../common/Button';
import BaseContainer from '../../common/BaseContainer';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import LandingBackgroundZaplatomat from '../../images/Landing-zaplatomat';

const subtitles = [
  <>
    W Zapłatomacie <strong>wygenerujesz</strong> płatności online dla Twoich
    klientów,
  </>,
  <>
    a system <strong>przypomni</strong> im o terminie zapłaty
  </>,
];

// const HeroImageWrapper = styled("div")(() => ({
//   position: "absolute",
//   right: "130px",
//   top: "165px",
//   zIndex: 0,
// }));

const HeroBr = styled('br')(({ theme }) => ({
  [theme.breakpoints.down('800')]: {
    display: 'none',
  },
}));

const BoxWrapper = styled('div')(() => ({
  color: 'white',
  position: 'relative',
  '&::before': {
    content: '" "',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(135deg, #3180de, #1e5abb, #69b3e6)',
    opacity: 0.75,
  },
}));

const Hero = () => (
  <LandingBackgroundZaplatomat>
    <BoxWrapper>
      <BaseContainer>
        <Navbar loginLink={'https://zaplatomat.pl/user/sign-in'} />
        <Typography
          variant='h2'
          component='h1'
          sx={{
            fontSize: { xs: '2.3rem', sm: '4rem', md: '5rem', lg: '5.4rem' },
            fontWeight: 'bold',
            mt: 8,
            zIndex: 100,
            position: 'relative',
          }}
          color='white'
        >
          Przypominamy o <HeroBr />
          płatnościach
        </Typography>
        <Grid container sx={{ my: 4 }} spacing={2}>
          {subtitles.map((subtitle, idx) => (
            <Grid item xs={12} key={idx}>
              <Typography
                variant='subtitle1'
                sx={{
                  color: 'white',
                  fontWeight: 'light',
                  position: 'relative',
                  fontSize: { xs: '15px', sm: '16px' },
                }}
              >
                {subtitle}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', lg: 'flex-start' },
          }}
        >
          <a
            href='https://zaplatomat.pl/user/register'
            style={{ textDecoration: 'none' }}
          >
            <CallToActionButton
              endIcon={<ArrowForwardOutlinedIcon />}
              // sx={{ mt: 6, mb: { xs: 8, lg: 10 } }}
              sx={{
                mt: { xs: 3, sm: 6 },
                mb: { xs: 6, lg: 10 },
                fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px' },
                paddingY: { xs: '10px', sm: '12px', md: '14px', lg: '16px' },
                paddingX: { xs: '15px', sm: '32px', md: '48px', lg: '64px' },
              }}
            >
              Wypróbuj teraz
            </CallToActionButton>
          </a>
        </Box>
      </BaseContainer>
    </BoxWrapper>
  </LandingBackgroundZaplatomat>
);

export default Hero;
