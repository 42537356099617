import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import TextTransform from '../TextTransform';
import { styled } from '@mui/material/styles';
import { StaticImage } from 'gatsby-plugin-image';
import { lighten } from '@mui/material/styles';

const TEXTS = [
  'Masz dość przypominania o zrobieniu Ci przelewu?',
  'Czekasz na zapłatę za zrealizowane zlecenie?',
  'Nie masz okazji się spotkać, żeby odebrać gotówkę za wynajem mieszkania?',
  'Niezręcznie Ci jest po raz kolejny upominać się o zwrot pożyczki?',
];

const PREFIX = 'Landing';
const classes = {
  sectionThree: `${PREFIX}-sectionThree`,
  sectionSix: `${PREFIX}-sectionSix`,
  textSection: `${PREFIX}-textSection`,
  bold: `${PREFIX}-bold`,
};

const Root = styled('div')(({ theme }) => ({
  background: 'white',
  [`& .${classes.sectionThree}`]: {
    background: `linear-gradient(
      90deg,
      ${lighten(theme.palette.primary.lighter, 0.2)}  0%,
      ${theme.palette.primary.darker} 100%)`,
    fontWeight: 'bold',
  },
  [`& .${classes.sectionSix}`]: {
    textAlign: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
  },
  [`& .${classes.textSection}`]: {
    textAlign: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
  },
  [`& .${classes.bold}`]: {
    color: theme.palette.common.white,
    fontWeight: '300',
  },
}));

const Landing = () => {
  return (
    <>
      <TextTransform text={TEXTS} backColor={'white'} />
      <Root>
        <Box className={classes.sectionThree}>
          <Container maxWidth='xl'>
            <Box className={classes.textSection}>
              <Typography
                variant='h4'
                paragraph
                className={classes.bold}
                sx={{
                  fontSize: {
                    xs: '22px',
                    sm: '25px',
                    md: '28px',
                    lg: '31px',
                    xl: '34px',
                  },
                  paddingTop: { xs: '12px', md: 0 },
                }}
              >
                Bramka płatności dla każdego
              </Typography>
              <Typography
                variant='h5'
                className={classes.bold}
                sx={{
                  fontSize: {
                    xs: '16px',
                    sm: '18px',
                    md: '20px',
                    lg: '22px',
                    xl: '24px',
                  },
                  paddingBottom: { xs: '18px', md: 0 },
                }}
              >
                Serwis umożliwia <strong>egzekwowanie należności</strong> od
                dłużnika. Zapłatomat to także wygodna bramka płatności dla osób
                bez działalności gospodarczej. Zapłatomat wyśle{' '}
                <strong>uprzejme przypomnienie o płatności</strong> i udostępnia
                płatnikom przelew natychmiastowy paybylink lub Blik. Ściąganie
                należności staje się łatwiejsze, a Ty możesz łatwo sprawdzić,{' '}
                <strong>czy przelew został wysłany na Twoje konto</strong>.
              </Typography>
            </Box>
          </Container>
        </Box>
        <Container maxWidth='xl'>
          <Box className={classes.sectionSix} textAlign='center'>
            <Typography
              variant='h4'
              paragraph
              sx={{
                fontSize: {
                  xs: '22px',
                  sm: '25px',
                  md: '28px',
                  lg: '31px',
                  xl: '34px',
                },
              }}
            >
              Jak działa Zapłatomat? To proste!
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Box textAlign={'center'}>
                  <Box py={2}>
                    <StaticImage
                      src='../../../images/hiw01.png'
                      alt='How it works'
                      placeholder='none'
                    />
                  </Box>
                  <Typography
                    variant='h5'
                    sx={{ fontSize: { xs: '18px', md: '24px' } }}
                  >
                    W zapłatomacie tworzysz link, który wysyłasz do płatnika
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box textAlign={'center'}>
                  <Box py={2}>
                    <StaticImage
                      src='../../../images/hiw02.png'
                      alt='How it works'
                      placeholder='none'
                    />
                  </Box>
                  <Typography
                    variant='h5'
                    sx={{ fontSize: { xs: '18px', md: '24px' } }}
                  >
                    Płatnik otwiera link i płaci szybkim przelewem lub BLIKiem
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box textAlign={'center'}>
                  <Box py={2}>
                    <StaticImage
                      src='../../../images/hiw03.png'
                      alt='How it works'
                      placeholder='none'
                    />
                  </Box>
                  <Typography
                    variant='h5'
                    sx={{ fontSize: { xs: '18px', md: '24px' } }}
                  >
                    Pieniądze trafiają na Twoje konto bankowe
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Root>
    </>
  );
};

export default Landing;
