import { Box } from '@mui/material';
import React from 'react';
import EndInfoBanner from '../EndInfoBanner';

const textUp =
  'Klient lub znajomy zapomniał Ci zapłacić lub zwrócić pieniądze?';

const textDown =
  'Utwórz i wyślij link do szybkiej płatności, a Zapłatomat odświeży mu pamięć ;)';

const textButton = ' Zacznij korzystać';

const pageLink = 'https://zaplatomat.pl/user/register';

const Pricing = () => (
  <>
    <Box
      sx={{
        height: { xs: '32px', sm: '48px', md: '64px', lg: '80px', xl: '96px' },
        width: '100%',
        zIndex: -1,
        background: '#EBF2FA',
      }}
    />

    <EndInfoBanner
      textGridhWidth={8}
      textUp={textUp}
      textUpFoWe={400}
      textDown={textDown}
      textDoFoWe={400}
      textButton={textButton}
      pageLink={pageLink}
    />
  </>
);

export default Pricing;
