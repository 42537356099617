import React from 'react';
import { lighten } from '@mui/material/styles';
import LandingSection from './../LandingSection';
import {
  AccountBalanceOutlined,
  CodeOutlined,
  ErrorOutlineOutlined,
  EventOutlined,
  MonetizationOnOutlined,
  SmsOutlined,
} from '@mui/icons-material';
import fiberpayLogo from '../../../images/fiberpay_logo.svg';
import Features from '../Features';

const products = [
  {
    name: 'Link do płatności',
    description: (
      <>
        Wygeneruj link do szybkiej płatności i wyślij go SMSem, komunikatorem
        lub emailem do osoby, która ma Ci zapłacić
      </>
    ),
    icon: <SmsOutlined sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/',
  },
  {
    name: 'Sposoby zapłaty',
    description: (
      <>
        Odbiorca dostanie link do szybkiej płatności online, BLIK i dane do
        przelewu tradycyjnego
      </>
    ),
    icon: <MonetizationOnOutlined sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
  {
    name: 'Przypomnienia',
    description: (
      <>
        Wyślemy płatnikowi przypomnienia o terminie płatności, a Ciebie
        poinformujemy, kiedy zapłaci
      </>
    ),
    icon: <EventOutlined sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberaml/',
  },
  {
    name: 'Wezwanie do zapłaty (wkrótce)',
    description: (
      <>
        Jeżeli płatność nie została dokonana w terminie, pomożemy Ci formalnie
        wezwać dłużnika do zapłaty
      </>
    ),
    icon: <ErrorOutlineOutlined sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fibertoken/',
  },
  {
    name: 'Windykacja (wkrótce)',
    description: (
      <>
        Możesz zdecydować się na skierowanie nieopłaconej w terminie płatności
        do windykacji przez naszych partnerów
      </>
    ),
    icon: <AccountBalanceOutlined sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fibertoken/',
  },
  {
    name: 'Płatności cykliczne',
    description: (
      <>
        Zdefiniuj powtarzalne płatności od Twoich klientów, a ZAPŁATOMAT
        dopilnuje aby były zapłacone. Idealne dla szkół, stowarzyszeń i
        rentierów
      </>
    ),
    icon: <CodeOutlined sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fibertoken/',
  },
];

const Products = () => (
  <LandingSection
    columnText={false}
    sx={{
      background: (
        theme
      ) => `url(${fiberpayLogo}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, 0.2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Funkcje Zapłatomatu'
    title='Jak nasza aplikacja pomoże Ci odzyskać należności'
  >
    <Features
      products={products}
      largeNum={4}
      buttonOn={false}
      avatarOff={true}
    />
  </LandingSection>
);

export default Products;
