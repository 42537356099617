import React from 'react';
import Footer from '../../components/common/Footer';
import Hero from '../../components/landing/zaplatomat/Hero';
import Landing from '../../components/landing/zaplatomat/Landing';
import Pricing from '../../components/landing/zaplatomat/Pricing';
import Products from '../../components/landing/zaplatomat/Products';
import Seo from '../../seo';

const IndexPage = () => {
  return (
    <>
      <Seo
        title='Zapłatomat - Bramka płatności i egzekwowanie należności | Fiberpay'
        description='W Zapłatomacie wygenerujesz płatności online dla Twoich klientów,
      a system przypomni im o terminie zapłaty'
        metaImage='meta_image_zaplatomat.png'
        currentUrl='https://fiberpay.pl/produkty/zaplatomat'
      />
      <Hero />
      <Landing />
      <Products />
      <Pricing />
      <Footer />
    </>
  );
};

export default IndexPage;
